import { gql } from '@apollo/client';

export const GET_MAINSLIDERS = gql`
  query {
    getMainSliders {
      idx
      title {
        en
        ko
        id
      }
      text {
        en
        ko
        id
      }
      url
      src
      postingDate
    }
  }
`;
