import * as React from 'react';
import { Img, LinkBtn, Text, Btn, AnchorBtn } from './ButtonStyle';
import { ButtonType } from './ButtonType';

function Button(P: ButtonType) {
  if (P.Link) {
    return (
      <LinkBtn to={`${P.href}`}>
        {P.Icon ? <Img src={P.iconSrc} /> : null}
        <Text>{P.children}</Text>
        {P.Arrow ? <Img Arrow src="/img/icon/arrow-right-primary.svg" /> : null}
      </LinkBtn>
    );
  }
  if (P.Anchor) {
    return (
      <AnchorBtn href={P.href} target="_blank">
        {P.Icon ? <Img src={P.iconSrc} /> : null}
        <Text>{P.children}</Text>
        {P.Arrow ? <Img Arrow src="/img/icon/arrow-right-primary.svg" /> : null}
      </AnchorBtn>
    );
  }
  return (
    <Btn onClick={P.onClick}>
      {P.Icon ? <Img src={P.iconSrc} /> : null}
      <Text>{P.children}</Text>
      {P.Arrow ? <Img Arrow src="/img/icon/arrow-right-primary.svg" /> : null}
    </Btn>
  );
}

Button.defaultProps = {
  href: '/404',
};

export default Button;
