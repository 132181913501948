import styled from 'styled-components';

export const Wrap = styled.div`
  display: flex;
  max-width: 1204px;
  margin: 0 auto;
  overflow: hidden;
  padding: 32px 32px 1px 32px;
  @media (max-width: 800px) {
    flex-direction: column;
  }
  @media (max-width: 720px) {
    padding: 32px 16px 1px 16px;
  }
`;
