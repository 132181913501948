/* eslint-disable @typescript-eslint/indent */
import styled, { css } from 'styled-components';

interface IsMobile {
  isMobile: boolean;
}
interface IsLocation {
  pathName: boolean;
}
export const Wrap = styled.header`
  position: fixed;
  top: 0;
  background-color: #fff;
  width: 100vw;
  z-index: 9999;
`;
export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 1204px;
  width: 100%;
  max-height: 44px;
  margin: 0 auto;
  padding: 0 32px;
  z-index: 9998;
  @media (max-width: 720px) {
    padding: 0 16px;
    & > nav,
    & > ul {
      display: none;
    }
  }
`;
export const LogoSet = styled.img`
  width: 145px;
  height: 28px;
  margin: 8px 0;
`;
export const Nav = styled.nav`
  margin: auto 0;
`;
export const Menu = styled.ul`
  ${({ theme }) => theme.type.p3};
  /* color: ${({ theme }) => theme.color.black}; */
  align-items: center;
  display: flex;
`;
export const NavLi = styled.li<IsLocation>`
  & + & {
    margin-left: 32px;
  }
  & > a,
  a:visited,
  a:link {
    color: ${props => (props.pathName ? props.theme.color.primary : props.theme.color.black)};
  }
  & > a:hover {
    color: ${({ theme }) => theme.color.primary};
  }
`;
export const Social = styled.ul`
  display: flex;
  justify-content: center;
`;
export const SocialLi = styled.li`
  margin: 10px 0;
  height: 24px;
  & + & {
    margin-left: 16px;
  }
  @media (max-width: 900px) {
    & + & {
      margin-left: 8px;
    }
  }
`;

export const SocialImg = styled.img`
  width: 24px;
  height: 24px;
`;
export const ChangeContainer = styled.div`
  width: 100%;
  position: absolute;
  background-color: ${({ theme }) => theme.color.white};
  border-radius: 0 0 8px 8px;
  right: 0;
  z-index: 0;
  opacity: 1;
  height: 0;
  width: 100px;
  overflow: hidden;
  transition: height 0.3s ease-in-out;
`;
export const CurrentLang = styled.div`
  display: flex;
  position: relative;
  padding: 10px 4px;
  z-index: 2;
  background-color: ${({ theme }) => theme.color.white};
`;
export const LangContainer = styled.div`
  position: relative;
  margin-left: 28px;
  cursor: pointer;
  &:hover ${ChangeContainer} {
    height: 162px;
    transition: height 0.3s ease-in-out;
  }
  &:hover ${CurrentLang} {
    background-color: ${({ theme }) => theme.color.gray010};
  }
  @media (max-width: 900px) {
    margin-left: 12px;
  }
`;
export const GlobalIcon = styled.img`
  width: 24px;
  height: 24px;
`;
export const LangText = styled.p`
  margin-left: 8px;
  height: 24px;
  ${({ theme }) => theme.type.p2};
  color: ${({ theme }) => theme.color.gray060};
`;

export const ChangeBtn = styled.button`

  width: 100%;
  background-color: ${({ theme }) => theme.color.gray010};
  padding: 8px 16px;
  ${({ theme }) => theme.type.p2};
  color: ${({ theme }) => theme.color.gray060};
  cursor: pointer;
  &:nth-child(1) {
    border-radius: 0;
  }
  &:nth-child(2) {
    border-radius: 0 0 8px 8px;
  }
  &:hover {
    background-color: ${({ theme }) => theme.color.gray020};
  }
`;

// Mobile Size
export const MenuBtn = styled.button`
  display: none;
  width: 34px;
  height: 44px;
  cursor: pointer;
  background-color: ${({ theme }) => theme.color.white};
  @media (max-width: 720px) {
    display: initial;
  }
`;
export const MenuIcon = styled.div<IsMobile>`
  margin: 10px 0 10px 10px;
  position: relative;
  width: 24px;
  height: 24px;
  ${props =>
    props.isMobile &&
    css`
      & > div:nth-child(1) {
        width: 32px;
        top: 0;
        transform: rotate(45deg);
        transform-origin: left;
        transition: all 0.2s ease-in-out;
      }
      & > div:nth-child(2) {
        display: none;
      }
      & > div:nth-child(3) {
        width: 32px;
        bottom: 0;
        transform: rotate(-45deg);
        transform-origin: left;
        transition: all 0.2s ease-in-out;
      }
    `}
`;
const Line = css`
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #383838;
  border-radius: 1px;
  transition: all 0.2s ease-in-out;
`;
export const TopLine = styled.div`
  top: 3px;
  transform: rotate(0deg);
  ${Line}
`;
export const MiddleLine = styled.div`
  top: 50%;
  transform: translateY(-50%);
  ${Line};
`;
export const BottomLine = styled.div`
  bottom: 3px;
  transform: rotate(0deg);
  ${Line}
`;
export const MobMenuCont = styled.div<IsMobile>`
  position: absolute;
  /* display: none; */
  top: 44px;
  bottom: 0;
  width: 100vw;
  height: 0;
  padding: 0;
  background-color: ${({ theme }) => theme.color.white};
  overflow: hidden;
  /* transform: translateY(-100vh); */
  z-index: 9997;
  transition: all 0.3s ease-in-out;
  ${props =>
    props.isMobile &&
    css`
      /* display: initial; */
      height: calc(100vh - 44px);
      padding: 44px 16px;
      transition: all 0.3s ease-in-out;
    `}
  @media (min-width:721px) {
    display: none;
  }
`;
export const MobMenu = styled.ul`
  ${({ theme }) => theme.type.p3}
  color: ${({ theme }) => theme.color.black};
  text-align: center;
  flex-direction: column;
  padding-bottom: 96px;
`;
export const MobMenuList = styled.li`
  margin: 0 32px;
  & + & {
    border-top: solid 1px ${({ theme }) => theme.color.gray020};
  }
  & > a {
    display: block;
    padding: 24px 0;
  }
`;
