import * as React from 'react';
import { MutableRefObject, useRef } from 'react';
import { GetElementWidth } from '../../api/window-width/WindowWidth';
import { Wrap, LogosWrap, Container, PartnerLogo } from './PartnersStyle';

const partnerData = [
  {
    name: 'nipa',
    src: '/img/logo/partners/nipa.svg',
  },
  {
    name: 'eco science technologe',
    src: '/img/logo/partners/eco-sciene-tec.svg',
  },
  {
    name: 'ecoflag',
    src: '/img/logo/partners/ecoflag.svg',
  },
  {
    name: 'xangle',
    src: '/img/logo/partners/xangle.svg',
  },
  {
    name: 'cobak',
    src: '/img/logo/partners/cobak.svg',
  },
  {
    name: 'nestree',
    src: '/img/logo/partners/nestree.svg',
  },
  {
    name: 'slowmist',
    src: '/img/logo/partners/slowmist.svg',
  },
  {
    name: 'seven eleven kr',
    src: '/img/logo/partners/seveneleven-korea.svg',
  },
  {
    name: 'seven eleven vn',
    src: '/img/logo/partners/seveneleven-vietnam.svg',
  },
  {
    name: 'living tv',
    src: '/img/logo/partners/living-tv.svg',
  },
  {
    name: 'koast',
    src: '/img/logo/partners/koast.svg',
  },
  {
    name: 'weathex',
    src: '/img/logo/partners/weathex.svg',
  },
  {
    name: '4d solution',
    src: '/img/logo/partners/4d-solution.svg',
  },
  {
    name: 'Weather I',
    src: '/img/logo/partners/weather_i.svg',
  },
  {
    name: 'Upbit',
    src: '/img/logo/partners/upbit.svg',
  },
  {
    name: 'Bithumb',
    src: '/img/logo/partners/bithumb.svg',
  },
  {
    name: 'indodax',
    src: '/img/logo/partners/indodax.svg',
  },
];
const dbCount = partnerData.length;

function Partners() {
  const partnerRef = useRef() as MutableRefObject<HTMLDivElement>;

  const elementWidth = GetElementWidth(partnerRef, 'children', dbCount)!;
  const partnerWidth = elementWidth + dbCount * 64;
  return (
    <Wrap>
      <LogosWrap>
        <Container ref={partnerRef} position={partnerWidth} sec={70}>
          {partnerData.map(item => (
            <PartnerLogo key={item.name} src={item.src} alt={item.name} />
          ))}
          {partnerData.map(item => (
            <PartnerLogo key={`${item.name}-clone`} src={item.src} alt={item.name} />
          ))}
        </Container>
        <Container position={partnerWidth} sec={90}>
          {partnerData.reverse().map(item => (
            <PartnerLogo key={item.name} src={item.src} alt={item.name} />
          ))}
          {partnerData.map(item => (
            <PartnerLogo key={`${item.name}-clone`} src={item.src} alt={item.name} />
          ))}
        </Container>
      </LogosWrap>
    </Wrap>
  );
}

export default Partners;
