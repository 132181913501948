import * as React from 'react';
import { gql, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { RMItf, RMItfs, RMPlanItf } from '../../types';
import * as S from './RoadmapSysStyle';

const GET_ROADMAP = gql`
  query {
    getRoadmap {
      year
      title {
        en
        ko
        id
      }
      plan {
        idx
        complite
        text {
          en
          ko
          id
        }
      }
    }
  }
`;

function RoadmapSys() {
  const { i18n } = useTranslation();
  const currentLang = i18n.language;
  const { loading, error, data } = useQuery<RMItfs>(GET_ROADMAP);
  if (loading) return <h1>Loding...</h1>;
  if (error) return console.log(`Roadmap DB Error ${error}`);

  return (
    <S.Wrap>
      <S.Container>
        <S.Title> Roadmap</S.Title>
        {data &&
          Object.values(data.getRoadmap).map((item: RMItf) => (
            <S.PlanWrap key={item.year}>
              <S.Year>{item.year}</S.Year>
              <S.PlanContents>
                {titleRender(item.title, currentLang)}
                {/* {item.title.en.length > 0 ? (
                  <>
                    {
                      currentLang === 'ko' ? (
                        <>
                          {item.title.ko.split('\n').map((title, i) => (
                            <S.YearTitle key={String(i)}>{title}</S.YearTitle>
                          ))}
                        </>
                      ) : 
                      currentLang === 'id' ? (
                      
                        <>
                          {item.title.id.split('\n').map((title, i) => (
                            <S.YearTitle key={String(i)}>{title}</S.YearTitle>
                          ))}
                        </>
                      )
                      :
                    
                      (
                        <>
                          {item.title.en.split('\n').map((title, i) => (
                            <S.YearTitle key={String(i)}>{title}</S.YearTitle>
                          ))}
                        </>
                      )
                    }
                  </>
                ) : null} */}
                <S.PlanContainer>
                  {item.plan.map(plan => (
                    <S.Plan key={plan.idx}>
                      <S.IsComplite isComplite={plan.complite}>
                        <S.Circle />
                        <S.Line />
                      </S.IsComplite>
                      <S.TextArea>
                        <S.Triangle />
                        {planRender(plan, i18n.language)}
                        {/* {
                          // eslint-disable-next-line no-nested-ternary
                          currentLang === 'ko' ?
                            ( 
                              <S.Text>
                                {plan.text.ko.split('\n').map((text, i) => (
                                  <S.SplitText key={String(i)}>{text}</S.SplitText>
                                ))}
                              </S.Text>
                            )
                            : currentLang === 'id' ? 
                              (
                                <S.Text>
                                  {plan.text.id.split('\n').map((text, i) => (
                                    <S.SplitText key={String(i)}>{text}</S.SplitText>
                                  ))}
                                </S.Text>
                              )
                              : (
                                <S.Text>
                                  {plan.text.en.split('\n').map((text, i) => (
                                    <S.SplitText key={String(i)}>{text}</S.SplitText>
                                  ))}
                                </S.Text>
                              )
                        } */}
                        
                        {/* {currentLang === 'ko' ? (
                          <S.Text>
                            {plan.text.ko.split('\n').map((text, i) => (
                              <S.SplitText key={String(i)}>{text}</S.SplitText>
                            ))}
                          </S.Text>
                        ) : 
                        currentLang === 'id' ?(
                          <S.Text>
                            {plan.text.ko.split('\n').map((text, i) => (
                              <S.SplitText key={String(i)}>{text}</S.SplitText>
                            ))}
                          </S.Text>
                        ):
                        (
                          <S.Text>
                            {plan.text.en.split('\n').map((text, i) => (
                              <S.SplitText key={String(i)}>{text}</S.SplitText>
                            ))}
                          </S.Text>
                        )} */}
                      </S.TextArea>
                    </S.Plan>
                  ))}
                </S.PlanContainer>
              </S.PlanContents>
            </S.PlanWrap>
          ))}
      </S.Container>
    </S.Wrap>
  );
}
export default RoadmapSys;

function titleRender(pTitle:RMItf['title'], currentLang:string) {
  if (currentLang === 'ko') {
    if (pTitle.ko.length <= 0) return null;
    return (
      <>
        {pTitle.ko.split('\n').map((title, i) => (
          <S.YearTitle key={String(i)}>{title}</S.YearTitle>
        ))}
      </>
    );
  }
  if (currentLang === 'id') {
    if (pTitle.id.length <= 0) return null;
    return (
      <>
        {pTitle.id.split('\n').map((title, i) => (
          <S.YearTitle key={String(i)}>{title}</S.YearTitle>
        ))}
      </>
    );
  } 

  if (pTitle.en.length <= 0) return null;
  return (
    <>
      {pTitle.en.split('\n').map((title, i) => (
        <S.YearTitle key={String(i)}>{title}</S.YearTitle>
      ))}
    </>
  );
  
}

function planRender(plan:RMPlanItf, currentLang:string){
  console.log(plan);
  
  if (currentLang === 'ko') {
    return (
      <S.Text>
        {plan.text.ko.split('\n').map((text, i) => (
          <S.SplitText key={String(i)}>{text}</S.SplitText>
        ))}
      </S.Text>
    );
  }
  if (currentLang === 'id') {
    return (
      <S.Text>
        {plan.text.id.split('\n').map((text, i) => (
          <S.SplitText key={String(i)}>{text}</S.SplitText>
        ))}
      </S.Text>
    );
  }
  return (
    <S.Text>
      {plan.text.en.split('\n').map((text, i) => (
        <S.SplitText key={String(i)}>{text}</S.SplitText>
      ))}
    </S.Text>
  );
}
