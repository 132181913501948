import React from 'react';
import * as Sy from '../systems/index';

function Home() {
  return (
    <>
      {/* <Sy.NoticePopup /> */}
      <Sy.MainSlider />
      <Sy.DocCards />
      <Sy.Partners />
      <Sy.ProductWs />
      <Sy.ObserverApp />
      <Sy.CrowdSorce />
      <Sy.Utility />
      <Sy.Bigdata />
    </>
  );
}

export default Home;
