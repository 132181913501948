/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import * as S from './PrivacyPolicyStyle';

function PrivacyPolicyKo() {
  return (
    <S.Container>
      <S.Title>개인정보처리방침</S.Title>
      <S.Text>
        옵저버주식회사(이하 ‘옵저버’ 또는 회사’)는 회원의 개인정보보호를 소중하게 생각하고, 회원의
        개인정보를 보호하기 위하여 항상 최선을 다해 노력하고 있습니다.
        <br />
        옵저버는 서비스 기획부터 종료까지 정보통신망 이용촉진 및 정보보호 등에 관한 법률(이하
        ‘정보통신망법’), 개인정보보호법 등 국내의 개인정보 보호 법령을 철저히 준수합니다. 또한
        OECD의 개인정보 보호 가이드라인 등 국제 기준을 준수하여 서비스를 제공합니다.
      </S.Text>
      <S.HeadText>1. 개인정보처리방침</S.HeadText>
      <S.Text>
        옵저버는 이용자의 '동의를 기반으로 개인정보를 수집, 이용 및 제공'하고 있으며, '이용자의 권리
        (개인정보 자기결정권)를 적극적으로 보장'합니다.
        <br />
        회사는 정보통신서비스제공자가 준수하여야 하는 대한민국의 관계 법령 및 개인정보보호 규정,
        가이드라인을 준수하고 있습니다.
        <br />
        옵저버는 '개인정보처리방침'을 제정하여 이를 준수하고 있으며, 이를 인터넷사이트 및 모바일
        어플리케이션에 공개하여 이용자가 언제나 용이하게 열람할 수 있도록 하고 있습니다.
        <br />
        개인정보 처리방침”이란 이용자의 소중한 개인정보를 보호함으로써 이용자가 안심하고 서비스를
        이용할 수 있도록 회사가 준수해야 할 지침을 의미합니다.
        <br />본 개인정보 처리방침은 옵저버 서비스(이하 '옵저버'또는 '서비스'라 함)에 적용됩니다.
      </S.Text>
      <S.HeadText>2. 개인정보수집</S.HeadText>
      <S.Text>
        이용자는 회원가입을 하지 않아도 옵저버 홈페이지 대부분의 서비스와 어플리케이션 날씨서비스를
        회원과 동일하게 이용할 수 있습니다.
        <br />
        옵저버 스토어와 어플리케이션에서의 관측, 보상 등과 같은 개인화 혹은 회원제 서비스를 이용하기
        위해 회원가입을 할 경우 옵저버는 서비스의 원활한 이용을 위해 필요 최소한의 개인정보를
        수집합니다.
      </S.Text>
      <S.BoldText>개인정보를 수집하는 방법은 다음과 같습니다.</S.BoldText>
      <S.Text>
        - 회원가입 및 서비스 이용과정에서 이용자가 개인정보 수집에 대해 동의를 하고 직접 정보를
        입력하는 경우
        <br />
        -고객상담을 위한 상담과정에서 웹페이지, 메일, 팩스, 전화 등
        <br />
        - 제휴 서비스 또는 단체 등으로부터 개인정보를 제공받은 경우
        <br />
        - 온·오프라인에서 진행되는 이벤트, 세미나 등 참여
        <br />
        - 기기정보와 같은 생성정보는 PC웹, 모바일 웹/앱 이용 과정에서 자동으로 생성되어 수집
        <br />
      </S.Text>
      <S.BoldText>회원가입 과정에서 이용자로부터 수집하는 정보는 아래와 같습니다.</S.BoldText>
      <S.Text>
        [애플리케이션]
        <br />
        휴대전화번호, 이메일
        <br />
        <br />
        일부 서비스에서는 특화된 여러 기능들을 제공하기 위해 공통으로 수집하는 정보 외 이용자 동의
        후 추가적인 개인정보를 수집할 수 있습니다.
        <br />
        *필수정보:해당 서비스의 본질적 기능을 수행하기 위한 정보
        <br />
        *선택정보: 보다 특화된 서비스를 제공하기 위해 추가수집하는 정보로써 입력하지 않아도 서비스
        이용제한은 없습니다.
        <br />
        <br />
        PC웹, 모바일 웹/앱 등 서비스 이용 과정에서 IP 주소, 쿠키, 방문일시, 부정이용기록, 서비스
        이용 기록, 기기정보(OS, 화면사이즈, 디바이스 아이디, 위치정보가 등의 정보가 자동으로
        생성되어 수집될 수 있습니다.
        <br />
        구체적으로
        <br />
        1) 서비스 이용 과정에서 이용자에 관한 정보를 정보통신서비스 제공자가 자동화된 방법으로
        생성하여 이를 저장(수집)하거나,
        <br />
        2) 이용자 기기의 고유한 정보를 원래의 값을 확인하지 못 하도록 안전하게 변환한 후에 수집하는
        경우를 의미합니다.
        <br />
        옵저버 위치기반서비스 이용 시 수집, 저장되는 위치정보의 이용 등에 대한 자세한 사항은
        ‘위치정보 이용약관’에서 규정하고 있습니다.
      </S.Text>
      <S.BoldText>서비스 이용과정에서 수집하는 정보는 아래와 같습니다.</S.BoldText>
      <S.Text>
        [옵저버 스토어]
        <br />
        이름, 주소, 전화번호, 이메일
        <br />
        <br />
        [어플리케이션 관측 및 보상]
        <br />
        가상화폐 지갑주소, 위치정보
        <br />
      </S.Text>
      <S.HeadText>3. 개인정보의 이용</S.HeadText>
      <S.Text>
        옵저버 및 옵저버 관련 제반 서비스(모바일 웹/앱 포함)의 회원관리, 서비스 개발·제공 및 향상,
        안전한 인터넷 이용환경 구축 등 아래의 목적으로만 개인정보를 이용합니다.
        <br />
        - 회원 가입 의사의 확인, 이용자 식별, 회원탈퇴 의사의 확인 등 회원관리를 위한 경우
        <br />
        - 콘텐츠 등 기존 서비스 제공(광고 포함)에 더하여, 인구통계학적 분석, 서비스 방문 및
        이용기록의 분석, 개인정보 및 관심에 기반한 이용자간 관계의 형성, 지인 및 관심사 등에 기반한
        맞춤형 서비스 제공 등 신규 서비스 요소의 발굴 및 기존 서비스 개선 등을 위한 경우
        <br />
        - 법령 및 옵저버 이용약관을 위반하는 회원에 대한 이용 제한 조치, 부정 이용 행위를 포함하여
        서비스의 원활한 운영에 지장을 주는 행위에 대한 방지 및 제재, 계정도용 및 부정거래 방지, 약관
        개정 등의 고지사항 전달, 분쟁조정을 위한 기록 보존, 민원처리 등 이용자 보호 및 서비스 운영을
        위한경우
        <br />
        - 유료 서비스 제공에 따르는 본인인증, 구매 및 요금 결제, 상품 및 서비스의 배송을 위한 경우
        <br />
        - 이벤트 정보 및 참여기회 제공, 광고성 정보 제공 등 마케팅 및 프로모션 목적을 위한 경우
        <br />
        - 서비스 이용 기록과 접속 빈도 분석, 서비스 이용에 대한 통계, 서비스 분석 및 통계에 따른
        맞춤 서비스 제공 및 광고 게재 등을 위한 경우
        <br />
        - 보안, 프라이버시, 안전 측면에서 이용자가 안심하고 이용할 수 있는 서비스 이용환경 구축을
        위한 경우
        <br />
      </S.Text>
      <S.HeadText>4. 개인정보 수집, 이용, 제공에 대한 동의</S.HeadText>
      <S.Text>
        옵저버는 이용자가 최초 회원가입 시 또는 서비스 이용과 관련해 개인정보를 입력할 시
        개인정보처리방침 및 이용약관 등의 내용에 대해 동의 여부를 체크할 수 있는 절차를 마련하여,
        이를 체크하면 개인정보수집에 대해 동의한 것으로 처리합니다.
      </S.Text>
      <S.HeadText>5. 개인정보 제공 및 위탁</S.HeadText>
      <S.Text>
        1) 옵저버는 이용자의 개인정보를 “3. 개인정보의 수집 및 이용목적” 에서 고지한 범위 내에서
        사용하며 동 범위를 초과하여 이용하지 않습니다.
        <br />
        2) 다만 다음의 경우에는 합당한 절차를 통한 회원의 동의를 얻어 개인정보를 제공 또는 이용할 수
        있습니다.
        <br />
        - 제휴관계: 보다 나은 서비스 제공을 위하여 당사는 회원의 개인정보를 제휴사에 제공할 수
        있습니다. 이 경우 사전에 회원에게 제휴사, 제공되는 개인정보의 항목, 개인정보 제공 목적,
        제공기간, 개인정보보호방안 등에 대해서 개별적으로 동의를 구하는 절차를 거치게 되며, 회원의
        동의가 없는 경우에는 제휴사에게 이를 제공하지 않습니다.
        <br />
        - 위탁처리: 원활한 업무처리와 회원에게 보다 나은 서비스 제공을 위하여 회원의 개인정보를
        위탁하여 처리할 수 있으며, 이 경우 반드시 사전에 위탁처리 업체명과 목적, 위탁처리 되는
        개인정보의 범위, 위탁기간 등에 대해 상세하게 고지합니다.
        <br />
        3) 다음은 예외로 합니다.
        <br />
        - 서비스 제공에 따른 정산을 위하여 필요한 경우
        <br />
        - 회사가 제공하는 기상정보의 수집/저장/전송 서비스의 원활한 운영에 필요한 경우
        <br />
        - 이용자들이 사전에 동의한 경우
        <br />
        - 회사가 이용자에게 암호화폐, 포인트, 사이버머니 등의 보상을 하는 경우
        <br />
        - 통계작성, 학술연구 또는 시장조사를 위하여 필요한 경우로서 특정 개인을 식별할 수 없는
        형태로 제공하는 경우
        <br />
        - 도용방지를 위하여 본인확인에 필요한 경우
        <br />
        - 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가
        있는 경우
        <br />
        - 이용자의 생명이나 안전에 급박한 위험이 확인되어 이를 해소하기 위한 경우
        <br />에 한하여 개인정보를 제공하고 있습니다.
      </S.Text>
      <S.HeadText>6. 개인정보의 보유기간 및 파기</S.HeadText>
      <S.Text>
        1) 회사가 회원의 개인정보를 수집하는 경우 그 보유기간은 원칙적으로 회원가입 후 약관 제9조에
        의한 ‘이용계약의 종료시’까지며, 이용계약 종료의 경우 회사는 회원의 개인정보를 즉시 파기하며
        제3자에게 기 제공된 정보에 대해서도 지체 없이 파기하도록 조치합니다. (단, 재가입유예기간
        동안의 재가입 방지 등을 위해 이용계약이 종료한 날로부터 6개월 경과 후에 파기하도록 합니다.)
        <br />
        또한, 다음의 경우 각각 명시한 기간 동안 개인정보를 보유합니다.
        <br />
        ① 상법 등 법령의 규정에 의하여 보존할 필요성이 있는 경우 법령에서 규정한 보존기간 동안
        거래내역과 최소한의 기본정보를 보유
        <br />
        ② 보유기간을 미리 공지하고 그 보유기간이 경과하지 아니한 경우와 개별적으로 회원의 동의를
        받을 경우에는 약정한 기간 동안 보유
        <br />
        ③ 다음의 경우 명시한 기간 동안 개인정보를 보유
        <br />
        - 계약또는 청약철회 등에 관한 기록: 5년
        <br />
        - 소비자의 불만 또는 분쟁처리에 관한 기록: 3년
        <br />
        - 부정이용 등에 관한 기록: 5년
        <br />
        2) 회사는 고객의 개인정보를 보호하여 개인정보 유출로 인한 피해가 발생하지 않도록 하기 위하여
        다음과 같은 방법을 통하여 개인정보를 파기합니다.
        <br />
        - 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.
        <br />- 전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여
        삭제합니다.
      </S.Text>
      <S.HeadText>7. 이용자의 권리와 그 행사방법</S.HeadText>
      <S.Text>
        1) 이용자는 언제든지 등록되어 있는 자신의 개인정보를 조회하거나 수정할 수 있으며 가입
        해지(탈퇴)를 요청할 수도 있습니다. 단, 회사는 다음과 같이 조회, 수정 등의 요청을 거절할 만한
        정당한 공익적 사유가 있는 경우에는 요청을 거부할 수 있습니다. <br />
        ① 법률에 따라 열람이 금지되거나 제한되는 경우
        <br />
        ② 다른 사람의 생명, 신체를 해할 우려가 있거나 다른 사람의 재산과 그 밖의 이익을 부당하게
        침해할 우려가 있는 경우
        <br />
        2) 회사는 이용자의 요청에 의해 해지 또는 삭제된 개인정보는 6조 “개인정보의 보유기간 및
        파기”에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고
        있습니다.
      </S.Text>
      <S.HeadText>
        8. 유럽연합 일반 개인정보보호법 (General Data Protection Regulation) 및 각 회원국의 법률
        준수
      </S.HeadText>
      <S.Text>
        유럽연합 내 이용자를 대상으로 서비스를 제공하는 경우, 아래의 내용이 적용될 수 있습니다.
        <br />
        <br />
        [개인정보 처리의 목적 및 처리근거]
        <br />
        옵저버는 수립한 개인정보를 "3. 개인정보의 이용"에 기재된 목적으로만 이용하며, 사전에
        이용자에게 해당 사실을 알리고 동의를 구하고 있습니다.
        <br />
        그리고 GDPR 등 적용되는 법률에 따라, 옵저버는 다음 중 하나에 해당하는 경우에 이용자의
        개인정보를 처리할 수 있습니다.
        <br />
        - 정보주체의 동의
        <br />
        - 정보주체와의 계약의 체결 및 이행을 위한 경우
        <br />
        - 법적 의무사항 준수를 위한 경우
        <br />
        - 정보주체의 중대한 이익을 위해 처리가 필요한 경우
        <br />
        - 회사의 적법한 이익 추구를 위한 경우(정보주체의 이익과 권리 또는 자유가 그 이익보다 중요한
        경우는 제외)
        <br />
        <br />
        [유럽연합 내 이용자의 권리 보장]
        <br />
        GDPR 등 적용되는 법률에 따라, 이용자는 자신의 개인정보를 다른 관리자에게 이전해 달라고
        요청할 수 있고, 자신의 정보 처리 거부를 요청 할 수 있습니다. 그리고 이용자는 개인정보보호
        권한 당국에 불만을 제기할 권리가 있습니다. 또한 옵저버는 이벤트 및 광고 등 마케팅을 제공하기
        위해 개인정보를 활용할 수 있으며, 사전에 이용자의 동의를 구하고 있습니다. 원하지 않은 경우
        언제든지 동의를 철회할 수 있습니다.
        <br />
        위와 관련한 요청사항은 고객센터를 통해 서면, 전화 또는 이메일로 연락하시면 지체없이
        조치하겠습니다.
        <br />
        개인정보의 오류에 대한 정정을 요청하신 경우 정정을 완료하기 전까지 해당 개인정보를 이용 또는
        제공하지 않습니다.
        <br />
      </S.Text>
      <S.HeadText>9. 개인정보 자동 수집 장치의 설치/운영 및 거부에 관한 사항</S.HeadText>
      <S.Text>
        1) 쿠키란?
        <br />
        - 회사는 개인화되고 맞춤화된 서비스를 제공하기 위해서 이용자의 정보를 저장하고 수시로
        불러오는 '쿠키(cookie)'를 사용합니다.
        <br />
        - 쿠키는 웹사이트를 운영하는데 이용되는 서버가 이용자의 브라우저에게 보내는 아주 작은 텍스트
        파일로 이용자 컴퓨터의 하드디스크에 저장됩니다. 이후 이용자가 웹 사이트에 방문할 경우 웹
        사이트 서버는 이용자의 하드 디스크에 저장되어 있는 쿠키의 내용을 읽어 이용자의 환경설정을
        유지하고 맞춤화된 서비스를 제공하기 위해 이용됩니다.
        <br />
        - 쿠키는 개인을 식별하는 정보를 자동적/능동적으로 수집하지 않으며, 이용자는 언제든지 이러한
        쿠키의 저장을 거부하거나 삭제할 수 있습니다.
        <br />
        2) 회사의 쿠키 사용 목적
        <br />
        - 이용자들이 방문한 옵저버의 각 서비스의 접속여부, 기상정보 수집/저장/전송 여부, 문의하기,
        회사의 이용자 앞 보상 등에 사용합니다.
        <br />
        3) 회사의 쿠키 거부 방법
        <br />
        - 이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서 이용자는 웹브라우저에서 옵션을
        설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든
        쿠키의 저장을 거부할 수도 있습니다.
        <br />
        - 다만, 쿠키의 저장을 거부할 경우에는 로그인이 필요한 옵저버 일부 서비스는 이용에 어려움이
        있을 수 있습니다.
        <br />
        * 쿠키 설정방법 예<br />
        ① Internet Explorer : 웹 브라우저 상단의 도구 → 인터넷 옵션 → 개인정보 → 고급
        <br />
        ② Chrome : 웹 브라우저 우측의 설정 메뉴 → 화면 하단의 고급 설정 표시 → 개인정보의 콘텐츠
        설정 버튼 → 쿠키
        <br />
      </S.Text>
      <S.HeadText>10. 개인정보의 기술적/관리적 보호 대책</S.HeadText>
      <S.Text>
        회사는 이용자들의 개인정보를 처리함에 있어 개인정보가 분실, 도난, 유출, 변조 또는 훼손되지
        않도록 안전성 확보를 위하여 기술적/관리적 대책을 강구하고 있습니다. 단, 이용자 본인의
        부주의나 인터넷 또는 통신상의 문제로 ID, 비밀번호 등 개인정보가 유출되어 발생한 문제에 대해
        회사는 일체의 책임을 지지 않습니다.
      </S.Text>
      <S.HeadText>11. 개인정보 보호책임자</S.HeadText>
      <S.Text>
        이름: 배규호
        <br />
        소속: 경영관리
        <br />
        직위: 부장
        <br />
        메일: observerfoundation@gmail.com
        <br />
        전화번호 : 070.4453.7620
      </S.Text>
      <S.HeadText>12. 개인정보 관리담당자</S.HeadText>
      <S.Text>
        이름: 이종원
        <br />
        소속: 경영관리
        <br />
        직위: 대리
        <br />
        메일: observerfoundation@gmail.com
        <br />
        전화번호 : 070.4453.7619
      </S.Text>
      <S.HeadText>13. 개정 전 고지 의무</S.HeadText>
      <S.Text>
        본 개인정보처리방침의 내용 추가, 삭제 및 수정이 있을 경우 개정 최소 7일 전에 ‘공지사항’을
        통해 사전 공지를 할 것입니다.
        <br />
        - 공고일자: 2019년 10월 1일
        <br />- 시행일자: 2019년 10월 8일
      </S.Text>
    </S.Container>
  );
}

export default PrivacyPolicyKo;
