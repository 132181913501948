/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import * as S from './PrivacyPolicyStyle';

function PrivacyPolicyEn() {
  return (
    <S.Container>
      <S.Title>Privacy Policy</S.Title>
      <S.Text>
        Observer Inc. (hereinafter referred to as ‘Observer’ or the ‘Company’) considers protection
        of personal information of members as upmost importance and will thrive to protect personal
        information of members at all times.
        <br />
        Observer thoroughly abides by domestic privacy protection regulations, including the Act on
        Promotion of Information and Communications Network Utilization and Information Protection
        (hereinafter referred to as the ‘Information and Communications Network Act’) and Personal
        Information Protection Act, in all stages of Observer services, from service planning to
        termination. In addition, Observer provides services in accordance with international
        standards, including the OECD Privacy Guidelines.
      </S.Text>
      <S.HeadText>1. Personal Information Processing Policy</S.HeadText>
      <S.Text>
        Observer uses and provides the user’s personal information based on the user’s consent, and
        actively guarantees the user’s right (the right to control one’s own personal information).
        <br />
        Observer complies with applicable laws, personal information protection regulations and
        guidelines of the Republic of Korea, which must be obeyed by information communication
        service providers.
        <br />
        Observer has set and abide by the ‘Personal Information Policy’ and has published this
        policy on the website and mobile application so that users can ready the full policy
        anytime.
        <br />
        Personal Information Policy refers to guidelines on protecting the user’s valuable personal
        information which Observer is required to comply with, in order to ensure that users use its
        services with confidence.
        <br />
        This Personal Information Policy is applied to Observer Services (hereinafter “Observer” or
        “the Services”).
      </S.Text>
      <S.HeadText>2. Collection of Personal Information</S.HeadText>
      <S.Text>
        Non-members can use most of the services without signing up including weather services and
        use of Observer’s website.
        <br />
        Observer will collect the minimum amount of personal information necessary for the use of
        the service upon signing up to provide personalized service or membership service such as
        weather data transmission, compensation, etc., in using Observer store and application.
      </S.Text>
      <S.BoldText>Methods of collecting personal information is following below.</S.BoldText>
      <S.Text>
        - The user agrees to the collection of personal information and enters relevant information
        when signing up for a service or while the user uses the Services.
        <br />
        - Through website, email, fax, telephone, etc., during provision of user support sessions.
        <br />
        - Personal information is provided through affiliated services or groups.
        <br />
        - The user participates in online and/or offline events.
        <br />- Device information is automatically made and collected while using Observer website,
        mobile web/apps.
      </S.Text>
      <S.BoldText>The following personal information is collected when signing up.</S.BoldText>
      <S.Text>
        [Application]
        <br />
        Mobile number, email
        <br />
        <br />
        Some services may collect personal information additional to those collected commonly by
        Observer Account upon the user‘s consent in order to provide specialized services.
        <br />
        * Required information: Information required to provide the fundamental features of the
        concerned service.
        <br />
        * Optional information: Additional information which needs to be collected to provide more
        specialized services. (Users can use the Services without agreeing to provide additional
        information.)
        <br />
        <br />
        Device information (OS, screen size, device ID), IP address, cookies, date of visit,
        improper usage record and service usage record may be automatically created and collected
        while the user uses the PC web or mobile web & apps.
        <br />
        In specific,
        <br />
        1) The information communications service provider may automatically generate information
        about the user while the user uses the service and save (collect) the information, or
        <br />
        2) The information is collected after information that is unique to the user’s device is
        safely converted so that the original value is unidentifiable.
        <br />
        Details on the collection and storing of location information when using Observer’s
        location-based services are regulated on “Location Information Policy”.
      </S.Text>
      <S.BoldText>List of personal information collected while using Service.</S.BoldText>
      <S.Text>
        [Observer Store]
        <br />
        Name, Address, Phone number, E-mail
        <br />
        <br />
        [Application weather observation and compensation]
        <br />
        Cryptocurrency wallet address, Location information
        <br />
      </S.Text>
      <S.HeadText>3. Use of the Collected Personal Information</S.HeadText>
      <S.Text>
        Observer uses personal information only for purposes described below in providing Observer
        and it’s related services (mobile web & app included) which includes managing subscribers,
        developing, providing and improving services, and creating a safe internet user environment,
        etc.
        <br />
        - For membership management such as verification of membership intention, identification of
        user, confirmation of withdrawal of membership.
        <br />
        - New service elements such as demographic analysis, analysis of service visits and usage
        records, formation of relationships between users based on personal information and
        interests, and customized services based on acquaintances and interests, in addition to
        providing existing services (including advertising) And to improve existing services.
        <br />
        - Notices such as prevention and sanctions against illegal use of the service including
        restrictions on use and illegal use of members who violate the laws and Observer usage
        conditions, prevention of account theft and illegal transactions, revision of the terms of
        use for preservation of records for dispute settlement, handling of complaints, etc.
        <br />
        -For self-verification, purchase and payment, and delivery of goods and services according
        to the provision of paid service.
        <br />
        - For marketing and promotional purposes, such as providing event information, participation
        opportunities, advertisement information, etc.
        <br />
        - For analysis of service use records and frequency of access, statistics on service use,
        analysis of services, customized service according to statistics, and advertisement.
        <br />- To establish a service utilization environment that users can use with confidence in
        terms of security, privacy, and safety.
      </S.Text>
      <S.HeadText>4. Agreement of collection, use, provision of personal information</S.HeadText>
      <S.Text>
        Observer conducts a procedure to check whether the user agrees to the contents of the
        personal information processing policy and the Terms of Use when entering the personal
        information at the time of initial member registration or while using the service, Observer
        treats as agreed after registration or using Observer Services.
      </S.Text>
      <S.HeadText>5. Provision and Entrustment of Personal Information</S.HeadText>
      <S.Text>
        1) Observer shall use the user's personal information under Article 3 “Use of collected
        personal information" within the scope of notice and do not exceed the range.
        <br />
        2) However, in the following cases, personal information can be provided or used with the
        consent of the members.
        <br />
        - Affiliation: In order to provide better service, we may provide your personal information
        to our affiliates. In this case, affiliated company will be required to individually obtain
        consent from the members to use personal information, the purpose of providing personal
        information, the period of provision, and the personal information protection plan.
        <br />
        - Consignment processing: In order to provide better service to members, the member's
        personal information can be entrusted and processed. In this case, Observer will notify
        members in detail such as the name and purpose of consignment processing, the range of
        personal information to be consigned.
        <br />
        3) The following are exceptions.
        <br />
        - When it is necessary for payment according to service provision.
        <br />
        - When it is necessary to provide better Service such as collection, storage and
        transmission of weather information.
        <br />
        - If the users have agreed in advance.
        <br />
        - When the Company compensates the users with Cryptocurrencies, points, cyber money, etc.
        <br />
        - When necessary for statistical writing, academic research, or market research, in the form
        of an unidentifiable individual.
        <br />
        - When it is necessary to confirm identity to prevent theft.
        <br />
        - If there is a request by the investigation agency in accordance with procedures and
        methods prescribed in the Act for the purposes of investigation or for the purpose of
        investigation.
        <br />- Observer will provide personal information only when the risk to the user's life or
        safety has been confirmed and to resolve it.
      </S.Text>
      <S.HeadText>6. Retention period and destruction of personal information</S.HeadText>
      <S.Text>
        1) If the Company collects the personal information of the member, the retention period is,
        in principle, until the end of the use contract pursuant to Article 9 of the Terms and
        Conditions. In case of termination of the use contract, the Company destroys personal
        information immediately after the user unsubscribes from the service. (However, The Company
        may keep the record for six months after the termination for the purpose of preventing
        re-entry during the re-entry grace period)
        <br />
        In addition, we will retain personal information for each specified period if:
        <br />
        ①If there is a necessity to preserve in accordance with the provisions of the Commercial Act
        such as Commercial Act, it shall have the transaction details and minimum basic information
        for the period of preservation prescribed by laws and regulations.
        <br />
        ②If the holding period has not been announced in advance and if the holding period has not
        elapsed, and if the member agrees to the holding period individually.
        <br />
        ③In the following cases, personal information will be retained for the specified period
        <br />
        - Records on subscription or withdrawal of subscription: 5 years
        <br />
        - Records of consumer complaints or disputes: 3 years
        <br />
        - Records on fraudulent use, etc.: 5 years
        <br />
        2) In order to protect the personal information of the users, the Company will destroy
        personal information through the following methods to prevent damage caused by personal
        information leakage.
        <br />
        - Information printed on paper is shredded or incinerated to prevent it from being restored
        for regenerated.
        <br />- Personal information stored in electronic files is safely deleted using technical
        methods
      </S.Text>
      <S.HeadText>7. User’s Rights and How to Exercise Those Rights</S.HeadText>
      <S.Text>
        1) Users can unsubscribe, view, update personal information at any time, However, the
        Company may refuse the request if there is a legitimate public interest reason to reject the
        request such as inquiry, modification, etc.
        <br />
        ①When viewing is prohibited or restricted by law.
        <br />
        ②If there is a risk of harming the life or body of another person or there is a possibility
        of unjustly infringing the property and other benefits of another person. <br />
        2) The Company shall process personal information that has been terminated or deleted at the
        user's request, as described in Article 6, "Retention period and destruction of personal
        information", and is prohibited from being viewed or accessed for other purposes. <br />
      </S.Text>
      <S.HeadText>
        8. General Data Protection Regulation (GDPR) as well as the domestic laws of each member
        country.
      </S.HeadText>
      <S.Text>
        The following may be applicable when Observer provides services to users in EU countries.
        <br />
        <br />
        [Purpose and processing of Personal Information]
        <br />
        Observer uses personal information collected from users only for purposes specified in
        Article 3. “Use of Collected Personal Information", informs users prior to any use and asks
        for consents.
        <br />
        In addition, Observer may process personal information in accordance with applicable laws
        including GDPR in any of the following cases:
        <br />
        - When agreed by the data subject
        <br />
        - For signing and fulfilling a contract with the data subject
        <br />
        - For legal compliance
        <br />
        - When personal information processing is necessary for the material benefit of the data
        subject
        <br />
        - For the pursuit of legitimate interests of the company (except for cases where the
        benefits, rights or freedom of the data subject is more important than that of the company.)
        <br />
        <br />
        [Guarantee of Users' Rights in EU Countries]
        <br />
        In accordance with applicable laws including GDPR, a user may request that his or her
        personal information be transferred to another manager and refuse the processing of his or
        her information. In addition, a user has a right to file a complaint with data privacy
        protection authorities.
        <br />
        Observer may also use personal information for marketing purposes such as event, promotion
        or advertisements, for those purposes Observer obtains agreement a prior to use. A user may
        withdraw the agreement at any time.
        <br />A user may contact the Customer Service of any request related to the above via paper,
        phone or email. The request will be handled in a proper and timely manner. When a user
        requests for the correction of personal information, the concerned information will not be
        displayed until correction is completed.
      </S.Text>
      <S.HeadText>
        9. About installation / operation and rejection of automatic collection of personal
        information
      </S.HeadText>
      <S.Text>
        1) What is a Cookie?
        <br />
        - A Cookie is a small piece of text file usually set by the web server, sent from a website
        and stored in a User's computer hard disk while the user is browsing that website.
        <br />
        - When a user visits the website, the website server reads cookies stored on the user's
        device to maintain environment settings and customized services. Cookies help the user use
        the website easily and conveniently by finding out the user's preferences.
        <br />
        - Also, they are used to provide customized marketing information by tracing the number of
        visiting to the website and use patterns.
        <br />
        2) Purpose of use of Cookie
        <br />
        Observer uses Cookies which save and retrieve user information in order to provide personal
        customized service such as weather information collection, storage, transmission, Q&A and
        compensation.
        <br />
        3) Rejecting a Cookie
        <br />
        - Cookie does not collect personally identifiable information automatically or actively, and
        a user has an option for setting a Cookie. Hence, a user may allow all Cookies by setting
        the option on the web browser; whenever a Cookie is stored, the confirmation thereof shall
        be completed; or, otherwise, a user may reject the storage of all Cookies provided.
        <br />
        - However, when a user rejects the storage of a Cookie, then the user may have some
        difficulties in using a service.
        <br />
        * Allow or Don't Allow Cookies.
        <br />
        ① Internet Explorer: Select "Tool" menu at the top of the browser. → "Internet Options" →
        "Personal Data" Tab → Settings
        <br />② Chrome: Select "Settings" menu at the top right side of the browser. → "Show
        advanced settings" → In the "Privacy" section, click "Content settings". → Cookies
      </S.Text>
      <S.HeadText>10. Efforts to Protect Personal Information</S.HeadText>
      <S.Text>
        The Company takes the following technical and managerial measures to ensure the safety of
        personal information in order to prevent loss, theft, leakage, alteration or damage of
        personal information in handling the users' personal information.
        <br />
        However, the Company does not take any responsibility for the problems caused by leakage of
        personal information such as ID, password due to user's intentions or negligence or internet
        or communication problems.
        <br />
        Privacy Protection Officer (CPO, DPO) and Responsible Department
        <br />
        Name: Bae Kyu Ho
        <br />
        Position: General Manager
        <br />
        Email: observerfoundation@gmail.com
        <br />
        Phone: 070.4453.7620
        <br />
        <br />
        Name: LEE JONG-WON
        <br />
        Affiliation: Management
        <br />
        Position: Manager
        <br />
        Email: observerfoundation@gmail.com
        <br />
        Phone: 070.4453.7619
      </S.Text>
      <S.HeadText>11. Amendment to the Privacy Policy</S.HeadText>
      <S.Text>
        The Company may amend its Personal Information Policy to reflect any legal or service
        changes after seven days advance notice.
        <br />
        - Announcement Date: October 1, 2019
        <br />- Effective Date: October 8, 2019
      </S.Text>
    </S.Container>
  );
}

export default PrivacyPolicyEn;
