import styled from 'styled-components';

interface Props {
  White?: boolean;
}

export const Wrap = styled.div`
  width: 100vw;
  height: 100vh;
  background: center/cover no-repeat linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url('img/provisioning/provisioning.jpg');
  @media (max-width: 640px) {
    background: center/cover no-repeat linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
      url('img/provisioning/provisioning-m.jpg');
  }
`;
export const Container = styled.div`
  max-width: 1204px;
  min-height: 554px;
  margin: 0 auto;
  padding: 32px;
  @media (max-width: 720px) {
    padding: 32px 16px;
  }
`;
export const ContentArea = styled.div<Props>`
  max-width: 538px;
  margin-top: 160px;
  color: ${props => (props.White ? props.theme.color.white : props.theme.color.black)};
  @media (max-width: 640px) {
    margin-top: 96px;
  }
`;
export const Title = styled.h2`
  ${({ theme }) => theme.type.h2};
`;
export const Text = styled.p`
  ${({ theme }) => theme.type.p1};
  margin-top: 16px;
`;
export const AlertText = styled.p`
  ${({ theme }) => theme.type.p1};
  color: ${({ theme }) => theme.color.alert};
  margin-top: 16px;
`;
export const ButtonArea = styled.div`
  display: flex;
  margin-top: 32px;
  a + a {
    margin-left: 16px;
  }
`;
