import * as React from 'react';
import { useTranslation } from 'react-i18next';
import * as S from './MainContentsStyle';

function Bigdata() {
  const { t } = useTranslation('main-contents');
  return (
    <>
      <S.Wrap
        style={{
          //   backgroundColor: "rgba(0, 0, 0, 0.5)",
          backgroundImage:
            'linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(/img/background/bigdata.jpg)',
        }}
      >
        <S.Container>
          <S.ContentArea White>
            <S.Title>{t('bigdata.title')}</S.Title>
            <S.Text>{t('bigdata.text')}</S.Text>
          </S.ContentArea>
        </S.Container>
      </S.Wrap>
    </>
  );
}
export default Bigdata;
