import * as React from 'react';
import { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Button } from '../../components';
import { Wrap, Container, ContentArea, Title, Text, ButtonArea } from './MainContentsStyle';

function ObserverApp() {
  const { t } = useTranslation('main-contents');
  const theme = useContext(ThemeContext);

  return (
    <Wrap obsrApp>
      <Container>
        <ContentArea>
          <Title>{t('observerApp.title')}</Title>
          <Text>{t('observerApp.text')}</Text>
        </ContentArea>
        <ButtonArea>
          <Button
            Anchor
            Icon
            href="https://apps.apple.com/kr/app/observer-snap/id6443979600"
            iconSrc={theme.iconSrc.ios}
          >
            App Store
          </Button>
          <Button
            Anchor
            Icon
            href="https://play.google.com/store/apps/details?id=org.observer.obsr"
            iconSrc={theme.iconSrc.android}
          >
            PlayStore
          </Button>
        </ButtonArea>
      </Container>
    </Wrap>
  );
}
export default ObserverApp;
