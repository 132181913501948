import styled from 'styled-components';

interface Active {
  isActive: boolean;
}
export const Slide = styled.div<Active>`
  position: relative;
  max-width: 1140px;
  width: calc(100% - 64px);
  height: 400px;
  margin: 0 8px;
  background-repeat: none;
  background-size: cover;
  background-position: center;
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  opacity: ${props => (props.isActive ? 1 : 0.4)};
  transition: opacity 0.5s ease-in-out;
  @media (max-width: 1140px) {
    width: calc(100% - 64px);
  }
  @media (max-width: 720px) {
    width: calc(100% - 32px);
  }
`;
export const Anchor = styled.a`
  position: absolute;
  width: 100%;
  height: 100%;
`;
export const Content = styled.div`
  position: absolute;
  margin: 0 128px;
  bottom: 32px;
  width: calc(80% - 256px);
  @media (max-width: 720px) {
    margin: 0 32px;
    width: calc(100% - 64px);
  }
`;
export const NewCard = styled.p`
  background: linear-gradient(96.9deg, #2da5f2 0%, #00e6ca 100%);
  border-radius: 4px;
  width: 125px;
  padding: 5px 8px;
  font-size: 12px;
  letter-spacing: -0.6px;
  color: #fff;
  text-align: center;
`;
export const H1 = styled.h1`
  ${({ theme }) => theme.type.h1}
  color: #fff;
  margin-top: 16px;
  @media (max-width: 560px) {
    ${({ theme }) => theme.type.h2}
  }
`;
export const P1 = styled.p`
  ${({ theme }) => theme.type.p1}
  color: #fff;
  margin-top: 16px;
`;
