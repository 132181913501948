import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './i18n';
import { ApolloProvider } from '@apollo/client'; // 수정

import App from './App';
import reportWebVitals from './reportWebVitals';
import Apollo from './apollo';

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={Apollo}>
      <Suspense fallback={null}>
        <App />
      </Suspense>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
