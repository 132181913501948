// import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Card = styled.div`
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  padding: 16px 24px;
  & + & {
    margin-left: 16px;
  }
  @media (max-width: 800px) {
    & + & {
      margin-left: 0;
      margin-top: 16px;
    }
  }
`;

export const Title = styled.h4`
  ${({ theme }) => theme.type.h4}
  color: ${({ theme }) => theme.color.black};
`;
export const DownArea = styled.div`
  display: flex;
  margin-top: 8px;
`;
export const DocLink = styled.a`
  display: flex;
  background-color: #fff;
  cursor: pointer;
  & + & {
    margin-left: 32px;
  }
`;
// export const DocLink = styled(Link)``;
export const LangText = styled.p`
  ${({ theme }) => theme.type.p1}
  color: ${({ theme }) => theme.color.black};
`;
export const Img = styled.img`
  width: 24px;
  height: 24px;
  margin: auto 0 auto 8px;
`;
