/* eslint-disable @typescript-eslint/indent */
import styled from 'styled-components';

interface Props {
  isComplite: boolean;
}
export const Wrap = styled.div`
  margin-top: 113px;
`;
export const Container = styled.div`
  max-width: 1204px;
  width: 100%;
  margin: 0 auto;
  padding: 0 32px;
  color: ${({ theme }) => theme.color.black};
  @media (max-width: 720px) {
    padding: 0 16px;
  }
`;
export const Title = styled.h2`
  ${({ theme }) => theme.type.h2};
`;
export const PlanWrap = styled.div`
  display: flex;
  margin-top: 32px;
`;
export const Year = styled.h3`
  vertical-align: top;
  ${({ theme }) => theme.type.h3};
  color: ${({ theme }) => theme.color.black};
`;
export const PlanContents = styled.div`
  margin-left: 16px;
`;
export const YearTitle = styled.h3`
  ${({ theme }) => theme.type.h3};
  color: ${({ theme }) => theme.color.primary};
  margin-bottom: 8px;
`;
export const PlanContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
export const Plan = styled.div`
  display: flex;
`;
export const IsComplite = styled.div<Props>`
  position: relative;
  & > div {
    background: ${props =>
      props.isComplite ? props.theme.color.primary : props.theme.color.gray020};
  }
`;
export const Circle = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 12px;
  height: 12px;
  border-radius: 6px;
`;
export const Line = styled.div`
  position: absolute;
  margin: 0 5px;
  height: 100%;
  width: 2px;
`;
export const TextArea = styled.div`
  margin-left: 12px;
  display: flex;
`;
export const Triangle = styled.div`
  width: 0;
  height: 0;
  margin: auto 0;
  border-top: 8px solid transparent;
  border-right: 16px solid ${({ theme }) => theme.color.gray010};
  border-bottom: 8px solid transparent;
`;
export const Text = styled.div`
  margin: 4px 0 4px -1px;
  padding: 5px 16px;
  border-radius: 20px;
  vertical-align: middle;
  ${({ theme }) => theme.type.p1};
  background-color: ${({ theme }) => theme.color.gray010};
`;
export const SplitText = styled.p``;

export const MonthWrap = styled.div`
  display: flex;
  flex-direction: column;
`;
export const MonthContainer = styled.div`
  display: flex;
  & + & {
    margin-top: 32px;
  }
`;
export const Month = styled.div`
  width: 2rem;
  text-align: right;
  margin: 0 16px;
  ${({ theme }) => theme.type.h3};
`;
export const TextWrap = styled.div`
  display: flex;
  flex-direction: column;
`;
