import { gql } from '@apollo/client';

export const GET_PAPERS = gql`
  query {
    getPapers {
      name
      language {
        en
        ko
        id
      }
    }
  }
`;
