import React from 'react';
import Routes from './Routes';
import Theme from './global-styles/Theme';
import './global-styles/import-fonts.css';
import { GlobalStyle } from './global-styles/GlobalStyle';
// import './i18n';

function App() {
  return (
    <Theme>
      <GlobalStyle />
      <Routes />
    </Theme>
  );
}

export default App;
