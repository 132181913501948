import styled from 'styled-components';

export const Wrap = styled.footer`
  background-color: #383838;
  width: 100vw;
  margin-top: 32px;
`;

export const Container = styled.div`
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.6px;
  text-align: center;
  margin: 0 auto;
  padding: 12px 0;
  max-width: 1140px;
  width: 100%;
`;
