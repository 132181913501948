import { Link } from 'react-router-dom';
import styled from 'styled-components';

interface Props {
  Arrow?: boolean;
}
export const Btn = styled.button`
  display: flex;
  padding: 8px 16px;
  border: solid 1px #e6e8e9;
  border-radius: 8px;
  cursor: pointer;
  background: ${props => props.theme.color.white};
  &:hover {
    background: ${props => props.theme.color.gray010};
  }
  &:active {
    background: ${props => props.theme.color.gray020};
  }
  & + & {
    margin-left: 16px;
  }
`;
export const LinkBtn = Btn.withComponent(Link);
export const AnchorBtn = Btn.withComponent('a');

export const Img = styled.img<Props>`
  width: ${props => (props.Arrow ? '20px' : '24px')};
  /* height: 24px; */
  margin: auto 0;
  span + & {
    margin-left: 16px;
  }
`;
export const Text = styled.span`
  ${({ theme }) => theme.type.p1};
  color: ${props => props.theme.color.primary};
  img + & {
    margin-left: 8px;
  }
`;
