import styled, { css } from 'styled-components';

interface Props {
  White?: boolean;
}
interface BackgroundImage {
  obsrApp?: boolean;
}
export const Wrap = styled.div<BackgroundImage>`
  width: 100vw;
  margin-top: 96px;
  background: right/cover no-repeat;
  ${props =>
    props.obsrApp &&
    css`
      background-image: url('/img/product/obsrapp.jpg');
      @media (max-width: 800px) {
        background-image: url('/img/product/obsrapp-m.jpg') !important;
        background: top center/cover no-repeat;
        background-position: 0 185px;
        & > div {
          background: linear-gradient(180deg, #ffffff 35%, rgba(255, 255, 255, 0) 60%);
        }
      }
    `}
`;
export const Container = styled.div`
  max-width: 1204px;
  min-height: 554px;
  margin: 0 auto;
  padding: 32px;
  @media (max-width: 720px) {
    padding: 32px 16px;
  }
`;
export const ContentArea = styled.div<Props>`
  max-width: 538px;
  color: ${props => (props.White ? props.theme.color.white : props.theme.color.black)};
`;
export const Title = styled.h2`
  ${({ theme }) => theme.type.h2};
`;
export const Text = styled.p`
  ${({ theme }) => theme.type.p1};
  margin-top: 16px;
`;
export const ButtonArea = styled.div`
  display: flex;
  margin-top: 32px;
  a + a {
    margin-left: 16px;
  }
`;
// 비디오--------------------------------------------------------
export const Video = styled.div`
  position: relative;
  max-width: 1140px;
  width: 100%;
  height: 0;
  margin-top: 32px;
  padding-bottom: 56.25%;
  overflow: hidden;
  & iframe {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    border-radius: 16px;
  }
`;
// 하단이미지--------------------------------------------------------
export const Img = styled.img`
  position: relative;
  max-width: 1140px;
  width: 100%;
  margin: 64px auto 0 auto;
`;

// 디바이스 관련--------------------------------------------------------
export const DeviceWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 32px;
`;
export const Device = styled.div`
  position: relative;
  margin: 4px;
  height: 220px;
  background: center/cover no-repeat;
  border-radius: 8px;
  max-width: calc(20% - 8px);
  ${({ theme }) => theme.type.h3};
  color: ${({ theme }) => theme.color.white};
  width: 100%;
  min-width: 220px;
  @media (max-width: 1203px) {
    max-width: calc(25% - 8px);
  }
  @media (max-width: 975px) {
    max-width: calc(33.33% - 8px);
  }
  @media (max-width: 747px) {
    max-width: calc(50% - 8px);
    min-width: 0;
    ${({ theme }) => theme.type.h4};
  }
`;
export const DeviceText = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
`;
// 산업 관련--------------------------------------------------------
export const IndustryWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 32px;
`;
export const Industry = styled.div`
  margin: 4px;
  border-radius: 8px;
  max-width: calc(50% - 8px);
  width: 100%;
  height: 640px;
  padding: 32px;
  background: center/cover no-repeat;
  @media (max-width: 680px) {
    max-width: calc(100% - 8px);
  }
`;
