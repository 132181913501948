import { makeVar } from '@apollo/client';
import { useState, useEffect } from 'react';

const getWindowWidth = () =>
  window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

export function GetWindowWidth() {
  const [width, setWidth] = useState(getWindowWidth());

  useEffect(() => {
    let timeoutId: any = null;

    const resizeLitener = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => setWidth(getWindowWidth()), 150);
    };

    window.addEventListener('resize', resizeLitener);

    return () => {
      window.removeEventListener('resize', resizeLitener);
    };
  }, []);
  return width;
}

export const winWidthVar = makeVar(0);
