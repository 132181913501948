import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
    * {
        box-sizing: border-box;
        padding: 0;
        margin: 0;
        text-decoration: none;
        border: none;
        outline: none;
    }
	body {
		padding: 0;
        margin: 0;
        background-color: #fff;
        word-break:keep-all;
    }
    h1,h2,h3,h4,h5,h6,p,span,a,button,ul,li{
        -webkit-text-size-adjust: antialiased;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: subpixel-antialiased;
        font-smooth: always;
        transform: rotate(-0.0000000001deg);
        text-shadow: 0 0 0.5px rgba(0,0,0,0.2);
    }
    ul li{
        list-style: none;
    }
    a {
        &:link,&:visited,&:active,&:hover{
            color:${({ theme }) => theme.color.black};
        }
    }
`;
