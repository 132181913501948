import React from 'react';
import * as Sy from '../systems/index';

function PrivacyPolicyEn() {
  return (
    <>
      <Sy.PrivacyPolicyEn />
    </>
  );
}

export default PrivacyPolicyEn;
