import * as React from 'react';
import { useTranslation } from 'react-i18next';
import * as S from './MainContentsStyle';

const deviceListDB = [
  {
    name: 'Smart Phone',
    src: 'smartphone.jpg',
  },
  {
    name: 'Weather Station',
    src: 'weatherstation.jpg',
  },
  {
    name: 'Automobile',
    src: 'automobile.jpg',
  },
  {
    name: 'Airplane',
    src: 'airplane.jpg',
  },
  {
    name: 'Vessle',
    src: 'vessle.jpg',
  },
];

function Device() {
  const { t } = useTranslation('main-contents');
  return (
    <>
      <S.Wrap>
        <S.Container>
          <S.ContentArea>
            <S.Title>{t('device.title')}</S.Title>
            <S.Text>{t('device.text')}</S.Text>
          </S.ContentArea>
          <S.DeviceWrap>
            {deviceListDB.map(item => (
              <S.Device
                key={item.name}
                style={{
                  backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(/img/background/device/${item.src})`,
                }}
              >
                <S.DeviceText>{item.name}</S.DeviceText>
              </S.Device>
            ))}
          </S.DeviceWrap>
        </S.Container>
      </S.Wrap>
    </>
  );
}
export default Device;
