import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  max-width: 554px;
  width: 100%;
  background: #fff;
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  & + & {
    margin-left: 32px;
  }
  @media (max-width: 931px) {
    flex-direction: column;
    text-align: center;
    & + & {
      margin-left: 16px;
    }
  }
`;
export const Img = styled.img`
  max-width: 240px;
  width: auto;
  max-height: 289px;
  height: auto;
  border-radius: 8px 0 0 8px;
  @media (max-width: 931px) {
    max-width: 240px;
    width: 100%;
    max-height: 289px;
    height: auto;
    margin: 0 auto;
  }
`;
export const Info = styled.div`
  margin: 24px;
`;
export const Title = styled.h3`
  ${({ theme }) => theme.type.h3}
  color: ${({ theme }) => theme.color.black};
`;
export const Spec = styled.p`
  ${({ theme }) => theme.type.p1}
  color: ${({ theme }) => theme.color.black};
  margin-top: 16px;
  & + & {
    margin-top: 0px;
  }
`;
