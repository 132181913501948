import React from 'react';
import * as Sy from '../systems/index';

function ProvisioningApp() {
  return (
    <>
      <Sy.ProvisioningApp />
    </>
  );
}

export default ProvisioningApp;
