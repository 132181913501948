/* eslint-disable react/jsx-indent */
/* eslint-disable @typescript-eslint/indent */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { LanguageItf, MSlideItf } from '../../types';
import * as S from './MainSlideStyle';

export default function MainSlide({ title, text, url, src, isNew, isActive }: MSlideItf) {
  const { i18n } = useTranslation();
  const currentLang = i18n.language;

  return (
    <S.Slide
      isActive={isActive}
      style={{
        backgroundImage: `linear-gradient(
      0deg, rgba(0, 0, 0, 0.3),
      rgba(0, 0, 0, 0.3)), url(${process.env.REACT_APP_IMG_MAINSLIDERS}${src})`,
      }}
    >
      <S.Content>
        {isNew ? <S.NewCard>NEW NOTIFICATIONS</S.NewCard> : null}
        <S.H1>{setTitle(title, currentLang)}</S.H1>
        <S.P1>{setDescription(title, currentLang)}</S.P1>
      </S.Content>
      {url ? <S.Anchor href={url} target="_blank" /> : null}
    </S.Slide>
  );
}

function setTitle(title:LanguageItf, currentLang:string) {
  if (currentLang === 'ko') {
    return title.ko;
  }
  if (currentLang === 'id') {
    return title.id;
  }
  return title.en;
}
function setDescription(text:LanguageItf, currentLang:string) {
  if (currentLang === 'ko') {
    return text.ko;
  }
  if (currentLang === 'id') {
    return text.id;
  }
  return text.en;
}
