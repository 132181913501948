import * as React from 'react';
import { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Button } from '../../components';
import {
  Wrap,
  Container,
  ContentArea,
  Title,
  Text,
  AlertText,
  ButtonArea,
} from './ProvisioningAppStyle';

function ProvisioningApp() {
  const { t } = useTranslation('provisioning-app');
  const theme = useContext(ThemeContext);
  const appDownload = () => {
    window.location.href = 'https://obsr.org/doc/provisioning-v1.apk';
  };
  return (
    <Wrap>
      <Container>
        <ContentArea White>
          {t('title', { returnObjects: true })
            .split('\n')
            .map(text => (
              <Title key={text}>{text}</Title>
            ))}
          <Text>{t('text')}</Text>
          <AlertText>{t('alert-text')}</AlertText>
        </ContentArea>
        <ButtonArea>
          <Button Icon iconSrc={theme.iconSrc.android} onClick={appDownload}>
            Version 1 전용
          </Button>
        </ButtonArea>
      </Container>
    </Wrap>
  );
}
export default ProvisioningApp;
