import styled from 'styled-components';

export const Container = styled.div`
  margin: 92px auto 0 auto;
  padding: 16px;
  max-width: 1140px;
`;
export const Title = styled.h2`
  ${({ theme }) => theme.type.h2};
  color: ${({ theme }) => theme.color.Black};
`;
export const Text = styled.p`
  ${({ theme }) => theme.type.p2};
  color: ${({ theme }) => theme.color.Gray90};
  margin-top: 32px;
`;
export const HeadText = styled.h3`
  ${({ theme }) => theme.type.h3};
  color: ${({ theme }) => theme.color.Gray90};
  margin-top: 64px;
`;
export const BoldText = styled.h5`
  ${({ theme }) => theme.type.h5};
  color: ${({ theme }) => theme.color.Gray90};
  margin-top: 32px;
`;
