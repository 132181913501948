import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as S from './HeaderStyle';

const SocialDB = [
  {
    src: '/img/icon/medium.svg',
    url: 'https://medium.com/obsr',
    name: 'Medium',
  },
  {
    src: '/img/icon/twitter.svg',
    url: 'https://twitter.com/observerfounda1',
    name: 'Twitter',
  },
  {
    src: '/img/icon/telegram.svg',
    url: 'https://t.me/obsrofficial',
    name: 'Telegram',
  },
  {
    src: '/img/icon/kakaotalk.svg',
    url: 'https://open.kakao.com/o/gPsdW8T',
    name: 'Kakao Talk',
  },
  {
    src: '/img/icon/github.svg',
    url: 'https://github.com/observernet',
    name: 'Github',
  },
];
function Header() {
  const { t, i18n } = useTranslation('header');

  const changeLanguageToKO = () => i18n.changeLanguage('ko');
  const changeLanguageToEN = () => i18n.changeLanguage('en');
  const changeLanguageToID = () => i18n.changeLanguage('id');
  const setLang = String(i18n.language).substr(0, 2);
  const currntLanguage = (props: string) => {
    if (props === 'ko') {
      return 'KO';
    }
    if (props === 'id') {
      return 'ID';
    }
    return 'EN';
  };

  const SocialLists = SocialDB.map(item => (
    <S.SocialLi key={item.name}>
      <a href={item.url} target="_blank" rel="noreferrer">
        <S.SocialImg src={item.src} alt={item.name} />
      </a>
    </S.SocialLi>
  ));
  const SocialAndLang = (
    <>
      {SocialLists}
      <S.LangContainer>
        <S.CurrentLang>
          <S.GlobalIcon src="/img/icon/global-language.svg" />
          <S.LangText>{currntLanguage(setLang)}</S.LangText>
        </S.CurrentLang>
        <S.ChangeContainer>
          <S.ChangeBtn onClick={changeLanguageToKO}>KO</S.ChangeBtn>
          <S.ChangeBtn onClick={changeLanguageToEN}>EN</S.ChangeBtn>
          <S.ChangeBtn onClick={changeLanguageToID}>ID</S.ChangeBtn>
        </S.ChangeContainer>
      </S.LangContainer>
    </>
  );

  const [mobMenu, setMobMenu] = useState(false);
  const menuToggle = () => {
    mobMenu ? setMobMenu(false) : setMobMenu(true);
  };
  const location = useLocation();
  const pathName = location.pathname;
  return (
    <S.Wrap>
      <S.Container>
        <Link to="/">
          <S.LogoSet src="/img/logo/logo.svg" />
        </Link>
        <S.Nav>
          <S.Menu>
            <S.NavLi pathName={pathName === '/'}>
              <Link to="/">{t('header:home')}</Link>
            </S.NavLi>
            <S.NavLi pathName={pathName === '/about'}>
              <Link to="/about">{t('header:about')}</Link>
            </S.NavLi>
            <S.NavLi pathName={pathName === '/roadmap'}>
              <Link to="/roadmap">{t('header:roadmap')}</Link>
            </S.NavLi>
            {/* <S.NavLi pathName={pathName === '/swap'}>
              <a href="https://swap.obsr-app.org/" target="_blank">{t('header:swap')}</a>
            </S.NavLi> */}

          </S.Menu>
        </S.Nav>
        <S.Social>{SocialAndLang}</S.Social>

        {/* Mobile Size */}
        <S.MenuBtn onClick={menuToggle}>
          <S.MenuIcon isMobile={mobMenu}>
            <S.TopLine />
            <S.MiddleLine />
            <S.BottomLine />
          </S.MenuIcon>
        </S.MenuBtn>
      </S.Container>
      <S.MobMenuCont isMobile={mobMenu}>
        <S.MobMenu>
          <S.MobMenuList>
            <Link to="/about" onClick={menuToggle}>
              {t('header:about')}
            </Link>
          </S.MobMenuList>
          <S.MobMenuList>
            <Link to="/roadmap" onClick={menuToggle}>
              {t('header:roadmap')}
            </Link>
          </S.MobMenuList>
          
          <S.MobMenuList>
            <a href="https://swap.obsr-app.org/" target="_blank">{t('header:swap')}</a>
          </S.MobMenuList>
        </S.MobMenu>
        <S.Social>{SocialAndLang}</S.Social>
      </S.MobMenuCont>
    </S.Wrap>
  );
}

export default Header;
