/* eslint-disable import/no-duplicates */
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';

// Get Browser Language
const userLanguage = window.navigator.language.substring(0, 2);
// const localLanguage = String(localStorage.getItem('language'));

// async;
i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    debug: false,
    load: 'languageOnly',
    lng: localStorage.getItem('language') || userLanguage || 'en',
    fallbackLng: 'en',
    backend: {
      loadPath: '/locales/{{ns}}/{{lng}}.json',
      allowMultiLoading: true,
    },
    keySeparator: '.', // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    react: {
      wait: true,
      useSuspense: true,
    },
  });
export default i18n;
