/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-plusplus */
import { useState, useLayoutEffect, useEffect, useCallback } from 'react';

const getWindowWidth = () =>
  window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

export function GetWindowWidth() {
  const [width, setWidth] = useState(getWindowWidth());

  useEffect(() => {
    let timeoutId = null;

    const resizeLitener = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => setWidth(getWindowWidth()), 150);
    };

    window.addEventListener('resize', resizeLitener);

    return () => {
      window.removeEventListener('resize', resizeLitener);
    };
  }, []);
  return width;
}

export function GetElementWidth(element, target, count) {
  const [width, setWidth] = useState(null);

  const updateWidth = useCallback(() => {
    if (target === 'child') {
      const { width } = element.current.childNodes[2].getBoundingClientRect();
      setWidth(width);
    } else if (target === 'children') {
      let totalWidth = 0;
      for (let i = 0; i < count; i++) {
        const { width } = element.current.childNodes[i].getBoundingClientRect();
        totalWidth += width;
      }
      setWidth(totalWidth);
    }
  }, [count, element, target]);

  useLayoutEffect(() => {
    let timeoutId = null;
    updateWidth();
    const resizeLitener = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => updateWidth(), 150);
    };

    window.addEventListener('resize', resizeLitener);
    window.addEventListener('load', resizeLitener);

    return () => {
      window.removeEventListener('resize', resizeLitener);
      window.removeEventListener('load', resizeLitener);
    };
  }, [element, updateWidth]);

  return width;
}
