import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import * as Page from './pages';
import { Header, Footer } from './components';
import ScrollToTop from './api/scroll-to-top/ScrollToTop';

function Routes() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Header />
      <Switch>
        <Route exact path="/" component={Page.Home} />
        <Route path="/about" component={Page.About} />
        <Route path="/roadmap" component={Page.Roadmap} />
        <Route path="/provisioning-app" component={Page.ProvisioningApp} />
        <Route path="/privacy-policy-ko" component={Page.PrivacyPolicyKo} />
        <Route path="/privacy-policy-en" component={Page.PrivacyPolicyEn} />
        <Redirect path="*" to="/" />
        {/* <Route path="*" component={Home} /> */}
      </Switch>
      <Footer />
    </BrowserRouter>
  );
}

export default Routes;
