import * as React from 'react';
import { useQuery } from '@apollo/client';
import { DocCard } from '../../components';
import { DocCardsItf } from '../../types';
import { Wrap } from './DocCardsStyle';
import { GET_PAPERS } from './query';

function DocCards() {
  const { loading, error, data } = useQuery<DocCardsItf>(GET_PAPERS);

  if (loading) return <h1>Loding...</h1>;
  if (error) return console.log(`Papers DB Error ${error}`);
  return (
    <Wrap>
      {data &&
        Object.values(data.getPapers).map(item => (
          <DocCard key={item.name} name={item.name} language={item.language} />
        ))}
    </Wrap>
  );
}
export default DocCards;
