import React from 'react';
import * as S from '../systems/index';

function About() {
  return (
    <>
      <S.CrowdSorce />
      <S.DocCards />
      <S.DataFlow />
      <S.Utility />
      <S.Bigdata />
      <S.Device />
      <S.Industry />
    </>
  );
}

export default About;
