import * as React from 'react';
import { useTranslation } from 'react-i18next';
import * as S from './MainContentsStyle';

function CrowdSorce() {
  const { t } = useTranslation('main-contents');
  return (
    <>
      <S.Wrap>
        <S.Container>
          <S.ContentArea>
            <S.Title>{t('crowdSorce.title')}</S.Title>
          </S.ContentArea>
          <S.Video>
            <iframe
              title="Observer Video"
              src="https://www.youtube.com/embed/Ci9I1GoviSM?vq=highres"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </S.Video>
        </S.Container>
      </S.Wrap>
    </>
  );
}
export default CrowdSorce;
