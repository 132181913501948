import React from 'react';
import { ThemeProvider, css } from 'styled-components';
// px -> rem
const toRem = size => `${size / 16}rem`;
const color = {
  primary: '#2DA5F2',
  secondary: '#0ED2D7',
  white: '#fff',
  black: '#020D14',
  blue030: '#85CCF2',
  gray010: '#F7F8F8',
  gray020: '#E6E8E9',
  gray030: '#CCD1D4',
  gray050: '#99A2A8',
  gray060: '#808B92',
  gray070: '#66747D',
  alert: '#FF6060',
};
const iconSrc = {
  ios: '/img/icon/appstore.svg',
  android: '/img/icon/playstore.svg',
};
const type = {
  h1: css`
    font-size: ${toRem(45)};
    letter-spacing: ${toRem(-0.6)};
    line-height: 1.3;
    font-weight: 700;
    @media (max-width: 768px) {
      font-size: ${toRem(41)};
    }
  `,
  h2: css`
    font-size: ${toRem(41)};
    letter-spacing: ${toRem(-0.6)};
    line-height: 1.3;
    font-weight: 700;
    @media (max-width: 768px) {
      font-size: ${toRem(30)};
    }
  `,
  h3: css`
    font-size: ${toRem(30)};
    letter-spacing: ${toRem(-0.6)};
    line-height: 1.3;
    font-weight: 700;
    @media (max-width: 768px) {
      font-size: ${toRem(25)};
    }
  `,
  h4: css`
    font-size: ${toRem(25)};
    letter-spacing: ${toRem(-0.6)};
    line-height: 1.5;
    font-weight: 700;
    @media (max-width: 768px) {
      font-size: ${toRem(20)};
    }
  `,
  h5: css`
    font-size: ${toRem(20)};
    letter-spacing: ${toRem(-0.6)};
    line-height: 1.6;
    font-weight: 700;
    @media (max-width: 768px) {
      font-size: ${toRem(16)};
    }
  `,
  h6: css`
    font-size: ${toRem(16)};
    letter-spacing: ${toRem(-0.6)};
    line-height: 1.6;
    font-weight: 700;
    @media (max-width: 768px) {
      font-size: ${toRem(14)};
    }
  `,

  p1: css`
    font-size: ${toRem(20)};
    letter-spacing: ${toRem(-0.6)};
    line-height: 1.6;
    font-weight: 400;
    @media (max-width: 768px) {
      font-size: ${toRem(16)};
    }
  `,
  p2: css`
    font-size: ${toRem(16)};
    letter-spacing: ${toRem(-0.6)};
    line-height: 1.6;
    font-weight: 400;
    @media (max-width: 768px) {
      font-size: ${toRem(14)};
    }
  `,
  p3: css`
    font-size: ${toRem(14)};
    letter-spacing: ${toRem(-0.6)};
    line-height: 1.6;
    font-weight: 400;
    @media (max-width: 768px) {
      font-size: ${toRem(12)};
    }
  `,
  p4: css`
    font-size: ${toRem(12)};
    letter-spacing: ${toRem(-0.6)};
    line-height: 1.6;
    font-weight: 400;
    @media (max-width: 768px) {
      font-size: ${toRem(10)};
    }
  `,
  p5: css`
    font-size: ${toRem(10)};
    letter-spacing: ${toRem(-0.6)};
    line-height: 1.6;
    font-weight: 400;
  `,
};
const theme = {
  color,
  iconSrc,
  type,
};
function Theme({ children }) {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
export default Theme;
