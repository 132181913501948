import React from 'react';
import { useTranslation } from 'react-i18next';
import { MwsCard } from '../../components';
import * as S from './ProductWsStyle';

interface VersionProps {
  name: string;
  sensor: string[];
  src: string;
}

function ProductWs() {
  const { t } = useTranslation('productWsDB');
  return (
    <S.Wrap>
      <S.Container>
        <S.TitleH5>{t('miniTitle')}</S.TitleH5>
        <S.TitleH2>{t('title')}</S.TitleH2>
        <S.TextP1>{t('text')}</S.TextP1>
        <S.CardContainer>
          {t<VersionProps[]>('version', { returnObjects: true }).map(item => (
            <MwsCard key={item.name} name={item.name} sensor={item.sensor} src={item.src} />
          ))}
        </S.CardContainer>
      </S.Container>
    </S.Wrap>
  );
}

export default ProductWs;
