import * as React from 'react';
import { useTranslation } from 'react-i18next';
import * as S from './MainContentsStyle';

function Utility() {
  const { t } = useTranslation('main-contents');
  return (
    <>
      <S.Wrap>
        <S.Container>
          <S.ContentArea>
            <S.Title>{t('utilityCoin.title')}</S.Title>
            <S.Text>{t('utilityCoin.text')}</S.Text>
          </S.ContentArea>
          <S.Img src="/img/background/utility.svg" />
        </S.Container>
      </S.Wrap>
    </>
  );
}
export default Utility;
