import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client'; // 수정
import { winWidthVar } from './store/WindowWidth';

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_DB_HOST,
});
const client = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          getWinWidth: {
            read() {
              return winWidthVar();
            },
          },
        },
      },
    },
  }),
});
export default client;
