import React from 'react';
import { Wrap, Container } from './FooterStyle';

function Footer() {
  const year = new Date().getFullYear();
  const copyright = `ⓒ Copyright 2018-${year} Observer Foundation & Observer Inc. - All Rights Reserved`;
  return (
    <Wrap>
      <Container>{copyright}</Container>
    </Wrap>
  );
}

export default Footer;
