import React from 'react';
import * as S from './MwsCardStyle';
import { Version } from './MwsProdDBType';

function MwsCard({ name, sensor, src }: Version) {
  return (
    <S.Container>
      <S.Img src={src} alt={name} />
      <S.Info>
        <S.Title>{name}</S.Title>
        {sensor.map(item => (
          <S.Spec key={item}>{item}</S.Spec>
        ))}
      </S.Info>
    </S.Container>
  );
}

export default MwsCard;
