import styled from 'styled-components';

export const Background = styled.div`
  width:100%;
  height: 100%;
  top:0;
  bottom:0;
  position: fixed;
  background: rgba(0, 0, 0, 0.7);
  z-index:9999;
`;

export const ContentsBackground = styled.div`
    max-width: 800px;
    min-width: 320px;
    width:calc(100vw - 320px);
    max-height:832px;
    height:calc(100% - 128px);
    background:#ffffff;
    position: fixed;
    z-index:9999;
    border-radius: 32px;
    top:50%;
    left: 50%;
    transform: translate(-50%,-50%);
    @media (max-width: 768px) {
        height:70%;
        border-radius: 16px;
    }
    @media (max-width: 375px) {
        width:calc(100vw - 40px);
        height:70%;
        border-radius: 16px;
    }
    
`;
export const Title = styled.h2`
    ${({ theme }) => theme.type.h3};
  color: ${({ theme }) => theme.color.black};
  margin: 32px 64px;
  @media (max-width: 768px) {
    margin: 16px 16px;
    }
    
`;
export const Contents = styled.div`
    margin: 0 64px 32px 64px;
    height:calc(100% - 128px - 32px);
    overflow: scroll;
    @media (max-width: 768px) {
        margin: 0 16px 16px 16px;
        height:calc(100% - 64px - 32px);
    }

    
`;
export const Content = styled.p`
    ${({ theme }) => theme.type.p2};
  color: ${({ theme }) => theme.color.black};
  min-height:32px;
  white-space: pre-wrap
    
`;