import * as React from 'react';
import { DocCardItf } from '../../types';
import * as S from './DocCardStyle';

function DocCard({ name, language }: DocCardItf) {
  return (
    <S.Card>
      <S.Title>{name}</S.Title>
      <S.DownArea>
        <S.DocLink href={`${process.env.REACT_APP_DOC}${language.en}`} target="_blank">
          <S.LangText>EN</S.LangText>
          <S.Img src="/img/icon/download.svg" alt="Download" />
        </S.DocLink>
        <S.DocLink href={`${process.env.REACT_APP_DOC}${language.ko}`} target="_blank">
          <S.LangText>KO</S.LangText>
          <S.Img src="/img/icon/download.svg" alt="Download" />
        </S.DocLink>
        <S.DocLink href={`${process.env.REACT_APP_DOC}${language.id}`} target="_blank">
          <S.LangText>ID</S.LangText>
          <S.Img src="/img/icon/download.svg" alt="Download" />
        </S.DocLink>
      </S.DownArea>
    </S.Card>
  );
}

export default DocCard;
