import * as React from 'react';
import { useTranslation } from 'react-i18next';
import * as S from './MainContentsStyle';

type FieldProps = {
  title: string;
  text: string;
  src: string;
};
function Industry() {
  const { t } = useTranslation('main-contents');
  return (
    <>
      <S.Wrap>
        <S.Container>
          <S.ContentArea>
            <S.Title>{t('industry.title')}</S.Title>
            <S.Text>{t('industry.text')}</S.Text>
          </S.ContentArea>
          <S.IndustryWrap>
            {t<FieldProps[]>('industry.field', { returnObjects: true }).map(item => (
              <S.Industry
                key={item.title}
                style={{
                  backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(img/background/industry/${item.src})`,
                }}
              >
                <S.ContentArea White>
                  <S.Title>{item.title}</S.Title>
                  <S.Text>{item.text}</S.Text>
                </S.ContentArea>
              </S.Industry>
            ))}
          </S.IndustryWrap>
        </S.Container>
      </S.Wrap>
    </>
  );
}
export default Industry;
