import styled from 'styled-components';

export const Wrap = styled.div`
  width: 100vw;
  margin-top: 128px;
`;
export const Container = styled.div`
  max-width: 1204px;
  margin: 0 auto;
  padding: 0 32px;
  color: ${({ theme }) => theme.color.black};
  @media (max-width: 720px) {
    padding: 0 16px;
  }
`;
export const TitleH5 = styled.h5`
  ${({ theme }) => theme.type.h5}
`;
export const TitleH2 = styled.h2`
  ${({ theme }) => theme.type.h2}
  margin-top: 8px;
`;
export const TextP1 = styled.p`
  ${({ theme }) => theme.type.p1}
  margin-top: 16px;
  max-width: 570px;
`;
export const CardContainer = styled.div`
  margin-top: 32px;
  display: flex;
`;
