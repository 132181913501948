import React, { MutableRefObject, useCallback, useLayoutEffect, useRef, useState } from 'react';
import { useQuery } from '@apollo/client';
import { MainSlide } from '../../components';
import { MSlidersItf, MSlideItf } from '../../types';
import * as S from './MainSliderStyle';
import { GetWindowWidth } from '../../api/window-width/WindowWidth';
import { GET_MAINSLIDERS } from './query';

export default function MainSlider() {
  const { loading, error, data } = useQuery<MSlidersItf>(GET_MAINSLIDERS);

  const MSlidersLength = data && data?.getMainSliders.length;
  const TotalSlideCount = MSlidersLength! + 4;

  // Ref --------------------------------------------------------------------
  const slidersRef = useRef() as MutableRefObject<HTMLDivElement>;

  // Get Window Size --------------------------------------------------------------------
  const windowWidth: number = GetWindowWidth();

  // Get Element Size --------------------------------------------------------------------
  const [elWidth, setElWidth] = useState(0);
  const getEleWidth = useCallback(() => {
    if (data) {
      const defaultValue = { width: 0 };
      setTimeout(() => {
        const { width } =
          slidersRef.current?.children[2]?.getBoundingClientRect?.() || defaultValue;
        setElWidth(width + 16);
      }, 20); // Add Slide Margin
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, slidersRef.current]);
  const sliderMargin: number = (windowWidth - elWidth) / 2;

  // Slide to Center --------------------------------------------------------------------
  const [center, setCenter] = useState(2);
  const sliderPosition: number = elWidth * center - sliderMargin; // Slide Position Inital Value
  const GoToFirstSlide = elWidth * 2 - sliderMargin;
  const GoToLastSlide = elWidth * (TotalSlideCount - 3) - sliderMargin;
  // sliderControll --------------------------------------------------------------------
  const prevButton = () => setCenter(center - 1);
  const nextButton = () => setCenter(center + 1);

  const SlideMapForm = (item: MSlideItf, i: number, Count: number) => {
    // isNewContent --------------------------------------------------------------------
    const isNewContnt = () => {
      const postingTimeStamp = Number(item?.postingDate) / 1000;
      const nowTimeStamp = Date.now() / 1000;
      const diffDate: number = nowTimeStamp - postingTimeStamp;
      if (MSlidersLength !== item.idx! + 1 && diffDate >= 2592000) {
        return false;
      }
      return true;
    };

    return (
      <MainSlide
        key={String(i + Count)}
        title={item.title}
        text={item.text}
        url={item.url}
        src={item.src}
        isNew={isNewContnt()}
        isActive={center - 2 === i}
      />
    );
  };
  // Touch controll
  const [touchPoint, setTouchPoint] = useState(0);
  const touchStartEvent = (e: React.TouchEvent<HTMLDivElement>) => {
    const touchStartPoint = e.touches[0].clientX;
    return setTouchPoint(touchStartPoint);
  };
  const touchEndEvent = (e: React.TouchEvent<HTMLDivElement>) => {
    const touchEndPoint = e.changedTouches[0].clientX;
    const moveDistance = touchEndPoint - touchPoint;
    if (moveDistance > elWidth / 4) {
      setCenter(center - 1);
    } else if (moveDistance < 0 && moveDistance < elWidth / 4) {
      setCenter(center + 1);
    }
  };
  const mouseDownEvent = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const mouseDownPoint = e.nativeEvent.clientX;
    return setTouchPoint(mouseDownPoint);
  };
  const dragEndEvent = (e: React.DragEvent<HTMLDivElement>) => {
    const dragEndPoint = e.nativeEvent.clientX;
    const moveDistance = dragEndPoint - touchPoint;
    if (moveDistance > elWidth / 4) {
      setCenter(center - 1);
    } else if (moveDistance < 0 && moveDistance < elWidth / 4) {
      setCenter(center + 1);
    }
  };

  // slide navi counter
  const naviCount = () => {
    if (center === TotalSlideCount - 2) {
      return 1;
    }
    if (center === 1) {
      return TotalSlideCount - 4;
    }
    return center - 1;
  };

  useLayoutEffect(() => {
    getEleWidth();
    const resizeLitener = () => {
      const timing = setTimeout(() => getEleWidth(), 150);
      clearTimeout(timing);
    };

    window.addEventListener('resize', resizeLitener);
    window.addEventListener('load', resizeLitener);
    // 슬라이더 트랜지션 --------------------------------------------------------------------
    const SliderControll = (transNum: number, moveTo: number) => {
      if (data) {
        slidersRef.current!.style.transform = `translateX(-${moveTo}px)`;
        slidersRef.current!.style.transition = `transform ${transNum}s ease-in-out`;
      }
    };
    // AutoPlay --------------------------------------------------------------------
    const autoPlay = setTimeout(() => {
      SliderControll(0.5, sliderPosition + elWidth);
      setCenter(center + 1);
    }, 6000);
    // infinity loop --------------------------------------------------------------------
    if (center === 1) {
      SliderControll(0.5, sliderPosition);
      setTimeout(() => {
        SliderControll(0, GoToLastSlide);
      }, 600);
      setTimeout(() => {
        setCenter(TotalSlideCount - 3);
      }, 620);
    } else if (center === TotalSlideCount - 2) {
      SliderControll(0.5, sliderPosition);
      setTimeout(() => {
        SliderControll(0, GoToFirstSlide);
      }, 600);
      setTimeout(() => {
        setCenter(2);
      }, 620);
    } else {
      SliderControll(0.5, sliderPosition);
    }

    return () => {
      window.removeEventListener('resize', resizeLitener);
      window.removeEventListener('load', resizeLitener);
      clearTimeout(autoPlay);
    };
  }, [
    GoToFirstSlide,
    GoToLastSlide,
    TotalSlideCount,
    center,
    data,
    elWidth,
    getEleWidth,
    sliderPosition,
  ]);
  if (loading) return <h1>Loding...</h1>;
  if (error) return console.log(`MainSliders DB Error ${error}`);
  return (
    <S.Wrap>
      {data && (
        <S.SlidersArea
          ref={slidersRef}
          position={sliderPosition}
          onTouchStart={touchStartEvent}
          onTouchEnd={touchEndEvent}
          onMouseDown={mouseDownEvent}
          onDragEnd={dragEndEvent}
        >
          {Object.values(data.getMainSliders)
            .slice(-2)
            .map((item, i) => SlideMapForm(item, i, -MSlidersLength!))}
          {Object.values(data.getMainSliders).map((item, i) => SlideMapForm(item, i, 0))}
          {Object.values(data.getMainSliders)
            .slice(0, 2)
            .map((item, i) => SlideMapForm(item, i, MSlidersLength!))}
        </S.SlidersArea>
      )}
      <S.Arrows>
        <S.Prev onClick={prevButton}>
          <img src="/img/icon/arrow-next.svg" alt="Previous" />
        </S.Prev>
        <S.Next onClick={nextButton}>
          <img src="/img/icon/arrow-next.svg" alt="Next" />
        </S.Next>
      </S.Arrows>
      <S.NaviCont>
        <S.NaviTotal>{`${naviCount()} / ${MSlidersLength}`}</S.NaviTotal>
      </S.NaviCont>
    </S.Wrap>
  );
}
