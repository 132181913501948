import styled from 'styled-components';

interface Props {
  position: number;
}
export const Wrap = styled.div`
  position: relative;
  width: 100vw;
  margin-top: 65px;
  overflow: hidden;
`;
export const SlidersArea = styled.div<Props>`
  will-change: transform;
  display: flexbox;
  transform: translateX(-${props => props.position}px);
  @media (max-width: 720px) {
    touch-action: pan-x;
  }
`;

export const Arrows = styled.div`
  position: absolute;
  z-index: 900;
  display: flex;
  justify-content: space-between;
  max-width: 1140px;
  width: 100%;
  padding: 24px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @media (max-width: 720px) {
    display: none;
  }
`;
export const Prev = styled.button`
  left: 24px;
  width: 64px;
  height: 64px;
  background: rgba(0, 0, 0, 0.2);
  cursor: pointer;
  img {
    width: 32px;
    height: 32px;
    margin: 16px;
    transform: rotate(180deg);
  }
`;
export const Next = styled.button`
  right: 24px;
  width: 64px;
  height: 64px;
  background: rgba(0, 0, 0, 0.2);
  cursor: pointer;
  img {
    width: 32px;
    height: 32px;
    margin: 16px;
  }
`;

export const NaviCont = styled.div`
  position: absolute;
  bottom: 8px;
  right: 32px;
  z-index: 900;
  display: none;
  @media (max-width: 720px) {
    display: initial;
  }
`;
export const NaviTotal = styled.p`
  position: relative;
  ${({ theme }) => theme.type.p2};
  color: ${({ theme }) => theme.color.white};
`;
