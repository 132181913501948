import styled, { keyframes } from 'styled-components';

interface Props {
  position: number;
  sec: number;
}
export const Wrap = styled.div`
  width: 100vw;
  margin-top: 96px;
`;
export const TitleWrap = styled.div`
  margin: 0 auto;
  padding: 0 32px;
`;
export const Title = styled.h2`
  ${({ theme }) => theme.type.h2}
  color: ${({ theme }) => theme.color.black};
`;
export const LogosWrap = styled.div`
  margin: 32px auto 0 auto;
  overflow: hidden;
`;
export const Container = styled.div<Props>`
  display: flex;
  overflow: initial;
  will-change: transform;
  animation: ${props => infinitySlide(props.position)} ${props => props.sec}s linear infinite both;
  & + & {
    margin-top: 32px;
  }
`;
const infinitySlide = (position: number) => keyframes`
  0% {
    transform: translateX(0px);
  }
  100% {
    transform: translateX(-${position}px);
  }
`;
export const PartnerLogo = styled.img`
  height: 40px;
  & + & {
    margin-left: 64px;
  }
  &:hover {
    filter: brightness(10%);
    transition: 0.5s;
  }
  @media (max-width: 768px) {
    height: 24px;
    & + & {
      margin-left: 32px;
    }
  }
`;
